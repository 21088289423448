import {CareEntryRowVariant, ExtendedCareEntrySearchResult} from "@components/CareEntryRow";
import {useDialog} from "@components/components/Dialog/useDialog";
import {TextInput} from "@components/Input";
import ResultList from "@components/Reason/CareDiscoverySearch/ResultList";
import RegionTreeSelector from "@components/Region/RegionTreeSelector";
import {Collapse} from "@mui/material";
import {useTranslation} from "ni18n";
import React, {ComponentProps, memo, useCallback} from "react";
import {usePrefersReducedMotion} from "src/hooks/usePrefersReducedMotion";

import CareEntryListRow from "@components/CareEntryRow";

import ScrollContainer from "../../../../page-containers/get-care/components/ScrollContainer";
import {Coordinate} from "../../../../store/types";
import useMediaQuery from "../../../../useMediaQuery";
import Slide, {TransitionPosition} from "../../../animations/Slide";
import DialogContent from "../../components/Dialog/DialogContent";
import Icon from "../../Icon";
import ImageCarousel, {SrcAndAlt} from "../../Location/ImageCarousel";
import {
  ApptReasonForCareEntryResultOptions,
  getSendClickedCareDiscoveryRowEvent,
} from "./careDiscoveryAnalytics";
import CloseSearchDialogButton from "./CloseSearchDialogButton";
import CloseTopicExpansionButton from "./CloseTopicExpansionButton";
import DialogTitle from "./DialogTitle";
import Error from "./Error";
import NoResults from "./NoResults";
import SearchDialogTrigger from "./SearchDialogTrigger";
import TopicExpansion, {RenderTopicInputs} from "./TopicScreen";
import {useCareDiscoverySearchController} from "./useCareDiscoverySearchController";
import {useTopicStack} from "./useTopicStack";
import {SelectedLocation} from "@components/CareEntryRow/Control";
import ControlledList from "@components/Reason/CareDiscoverySearch/ResultList/ControlledList";
import {getBorder} from "@components/Reason/CareDiscoverySearch/ResultList/border";
import {useHoveredIndexController} from "../../../../hooks/useHoveredIndexController";
import useNextPagesPaths from "../../../../hooks/app-pages-transition/useNextPagesPaths";
import {ReasonForCareEntryIcon} from "../../../../hooks/useCareEntryExtensions";
import {LocationForSoonestSlotForSlug} from "../../../../queries/useSoonestSlotByReasonSlug";

export type ReasonForCareDiscoverySearch = ApptReasonForCareEntryResultOptions &
  ReasonForCareEntryIcon;

type Props = {
  reasons: ReasonForCareDiscoverySearch[];
  locations: LocationForSoonestSlotForSlug[];
  viewId?: string;
  defaultResults?: ExtendedCareEntrySearchResult[];
  dialogTitle: string;
  hideHeadline?: boolean;
  hideRegionSelector?: boolean;
  headerImages?: (string | Coordinate | SrcAndAlt)[];
  selectedLocation?: SelectedLocation;
  isSearchEnabled?: boolean;
  defaultListRowVariant?: CareEntryRowVariant;
  defaultResultsLoadingCount?: number;
  hideDefaultListController?: boolean;
  hideDefaultListGradient?: boolean;
  phoneNumber?: string | null;
  doNotReportDefaultResultsOnMount?: boolean;
  /** is used to determine ResultList items are allowed firing AppointmentBookingStarted event. */
  canSendApptStartEvent?: boolean;
  defaultResultsFailed?: boolean;
};

const CareDiscoverySearch: React.FC<Props> = memo(
  ({
    reasons,
    locations,
    defaultResults = [],
    dialogTitle,
    hideHeadline,
    defaultResultsLoadingCount = 0,
    hideRegionSelector = false,
    headerImages,
    selectedLocation,
    isSearchEnabled = true,
    defaultListRowVariant = CareEntryRowVariant.LG,
    hideDefaultListController = false,
    hideDefaultListGradient = false,
    phoneNumber,
    canSendApptStartEvent = false,
    viewId,
    defaultResultsFailed,
  }) => {
    const i18n = useTranslation();
    const {pathname} = useNextPagesPaths();
    const isSm = useMediaQuery("sm");
    const {
      query,
      setQuery,
      isShortQuery,
      isNoSymptomsSearched,
      handleQueryChange,
      searchCareEntriesQuery,
      showDefaultResults,
      showNoResults,
      resultsToRender,
    } = useCareDiscoverySearchController(defaultResults, reasons);

    const topicExpansionStack = useTopicStack();

    const onStateChange = useCallback(
      (isOpen: boolean) => {
        if (!isOpen) {
          setQuery("");
          topicExpansionStack.reset();
        }
      },
      [setQuery, topicExpansionStack],
    );

    const {state: dialog, Dialog} = useDialog({
      title: "search-dialog",
      blurBackdrop: true,
      isTitleHidden: true,
      isFullWidth: true,
      onStateChange,
    });

    const maybeToggleDialog = useCallback(
      (shouldRedirect: boolean) => {
        if (!shouldRedirect && dialog.isClosed) {
          dialog.open();
        }
        if (shouldRedirect && dialog.isOpen) {
          setTimeout(dialog.close, 750);
        }
      },
      // Do not include the `dialog` reference as it changes on every render
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dialog.isClosed, dialog.open, dialog.isOpen, dialog.close],
    );

    const shouldReduceMotion = usePrefersReducedMotion();
    const transitionSpeed = shouldReduceMotion ? 0.1 : 200;
    const loadingRowCount = searchCareEntriesQuery.isLoading ? 5 : undefined;
    const {activeIndex, setHovered, unsetHovered} = useHoveredIndexController();

    return (
      <div className="flex flex-col" data-cy="care-discovery-search">
        {!hideHeadline && <DialogTitle className="block text-left mb-4">{dialogTitle}</DialogTitle>}
        {!hideRegionSelector && (
          <div className="-ml-2">
            <RegionTreeSelector />
          </div>
        )}
        {headerImages && (
          <ImageCarousel
            images={headerImages}
            imageWindowWidth={isSm ? "110%" : "112.5%"}
            imageScaleFactor={isSm ? 1.2 : 0.4}
          />
        )}
        {isSearchEnabled && (
          <>
            <SearchDialogTrigger onClick={dialog.open} />
            <Dialog>
              <Collapse in appear timeout={transitionSpeed}>
                <DialogContent>
                  <div className="w-[calc(100%+1rem)] mb-2 self-center flex relative justify-between flex-row-reverse z-10">
                    <CloseSearchDialogButton
                      onClose={dialog.close}
                      cypressKey="close-search-dialog"
                    >
                      <Icon icon="close1" className="gray800 text-[1.125rem]" />
                      <span className="visually-hidden">{i18n.t("Close modal")}</span>
                    </CloseSearchDialogButton>

                    {!topicExpansionStack.isEmpty && (
                      <CloseTopicExpansionButton
                        onClick={topicExpansionStack.pop}
                        cypressKey="close-topic-expansion"
                      >
                        <Icon icon="chevron-left" className="gray800 text-[1.125rem]" />
                      </CloseTopicExpansionButton>
                    )}
                  </div>
                  <DialogTitle
                    shouldAnchorToTop
                    className="mb-6"
                    data-cy="care-discovery-dialog-title"
                  >
                    {topicExpansionStack.title || i18n.t("What brings you in?")}
                  </DialogTitle>
                  <Slide
                    in={Boolean(topicExpansionStack.desciption)}
                    from={
                      topicExpansionStack.direction === "add"
                        ? TransitionPosition.RIGHT
                        : TransitionPosition.LEFT
                    }
                    duration={125}
                    unmountOnExit
                    appear
                  >
                    <p className="font-ir" data-cy="care-discovery-modal-description">
                      {topicExpansionStack.desciption}
                    </p>
                  </Slide>
                  <Collapse in={topicExpansionStack.isEmpty}>
                    {!hideRegionSelector && (
                      <div className="-ml-2 df mb1">
                        <RegionTreeSelector />
                      </div>
                    )}
                    <TextInput
                      onChange={handleQueryChange}
                      icon="search"
                      placeholder={i18n.t("Search symptoms or services")}
                      ariaLabel={i18n.t("Search symptoms or services")}
                      autoFocus
                      data-cy="discovery-search-input"
                      inputMode="text"
                    />
                  </Collapse>
                  <Collapse in={showNoResults} unmountOnExit appear timeout={transitionSpeed}>
                    <NoResults isNoSymptomsSearched={isNoSymptomsSearched} />
                  </Collapse>

                  <ScrollContainer
                    maxHeight={isSm ? "calc(100vh - 175px)" : "calc(70vh - 150px)"}
                    backgroundColor="#ffffff"
                  >
                    {topicExpansionStack.stack.map((topic, i, arr) => (
                      <TopicExpansion
                        key={`topic-screen-${topic.slug}`}
                        from={
                          topicExpansionStack.direction === "add"
                            ? TransitionPosition.RIGHT
                            : TransitionPosition.LEFT
                        }
                        render={inputRenderer({
                          locations,
                          maybeAddCareToTopicStack: topicExpansionStack.maybeAddToStack,
                          maybeToggleDialog,
                          reasons,
                          careEntryRowVariant:
                            topicExpansionStack.variant || CareEntryRowVariant.SM,
                          selectedLocation,
                          viewId,
                        })}
                        in={i === arr.length - 1}
                        care={topic}
                        isParentSearchActive={!showDefaultResults && Boolean(query)}
                        careEntryRowVariant={topicExpansionStack.variant || CareEntryRowVariant.SM}
                      />
                    ))}
                    {loadingRowCount ? (
                      <ResultList.Loading
                        reasons={reasons}
                        loadingRowCount={loadingRowCount}
                        keyPrefix={"search-results"}
                        rowVariant={CareEntryRowVariant.MD}
                        hideBorders
                      />
                    ) : (
                      <ResultList onMouseLeave={unsetHovered}>
                        {resultsToRender.map((careEntry, i, arr) => (
                          <ResultList.Item
                            className={getBorder(i, activeIndex, arr.length, true)}
                            hide={!topicExpansionStack.isEmpty}
                            onMouseEnter={() => setHovered(i)}
                            transitionSpeed={transitionSpeed}
                            key={`search-results-${i}-${careEntry.name}`}
                          >
                            <CareEntryListRow
                              reasons={reasons}
                              locations={locations}
                              cypressKey={`search-results-${careEntry.slug}`}
                              rank={i + 1}
                              result={careEntry}
                              selectedLocation={selectedLocation}
                              query={(!isShortQuery && !showNoResults && query) || undefined}
                              variant={CareEntryRowVariant.MD}
                              isLoading={false}
                              canSendApptStartEvent={canSendApptStartEvent}
                              reportClickEvent={
                                showDefaultResults
                                  ? getSendClickedCareDiscoveryRowEvent("base")
                                  : getSendClickedCareDiscoveryRowEvent("searched")
                              }
                              maybeToggleDialog={maybeToggleDialog}
                              maybeAddCareToTopicStack={topicExpansionStack.maybeAddToStack}
                              viewId={viewId}
                            />
                          </ResultList.Item>
                        ))}
                      </ResultList>
                    )}
                  </ScrollContainer>
                </DialogContent>
              </Collapse>
            </Dialog>
          </>
        )}

        <ScrollContainer
          disabled={isSm}
          maxHeight="calc(100vh - 300px)"
          hideGradient={hideDefaultListGradient}
        >
          {defaultResultsFailed ? (
            <Error />
          ) : defaultResultsLoadingCount ? (
            <ResultList.Loading
              reasons={reasons}
              loadingRowCount={defaultResultsLoadingCount}
              keyPrefix={"default-results"}
              rowVariant={defaultListRowVariant}
              hideBorders
            />
          ) : hideDefaultListController ? (
            <ResultList onMouseLeave={() => unsetHovered()} phoneNumber={phoneNumber}>
              {defaultResults.map((careEntry, i, arr) => (
                <ResultList.Item
                  key={`default-results-${i}-${careEntry.name}`}
                  className={getBorder(i, activeIndex, arr.length + (phoneNumber ? 1 : 0), false)}
                  disableAnimation={true}
                  onMouseEnter={() => setHovered(i)}
                  transitionSpeed={transitionSpeed}
                >
                  <CareEntryListRow
                    reasons={reasons}
                    locations={locations}
                    cypressKey={`default-results-${careEntry.slug}`}
                    rank={i + 1}
                    result={careEntry}
                    selectedLocation={selectedLocation}
                    variant={defaultListRowVariant}
                    canSendApptStartEvent={canSendApptStartEvent}
                    reportClickEvent={getSendClickedCareDiscoveryRowEvent("default", pathname)}
                    maybeToggleDialog={maybeToggleDialog}
                    maybeAddCareToTopicStack={topicExpansionStack.maybeAddToStack}
                    viewId={viewId}
                  />
                </ResultList.Item>
              ))}
            </ResultList>
          ) : (
            <ControlledList
              reasons={reasons}
              locations={locations}
              keyPrefix="default-results"
              selectedLocation={selectedLocation}
              careEntries={defaultResults}
              transitionSpeed={transitionSpeed}
              disableAnimation
              rowVariant={defaultListRowVariant}
              phoneNumber={phoneNumber}
              reportClickEvent={getSendClickedCareDiscoveryRowEvent("default", pathname)}
              maybeToggleDialog={maybeToggleDialog}
              maybeAddCareToTopicStack={topicExpansionStack.maybeAddToStack}
              canSendApptStartEvent={canSendApptStartEvent}
              viewId={viewId}
            />
          )}
        </ScrollContainer>
      </div>
    );
  },
);

const inputRenderer = ({
  locations,
  maybeAddCareToTopicStack,
  maybeToggleDialog,
  reasons,
  careEntryRowVariant,
  selectedLocation,
  viewId,
}: {
  careEntryRowVariant?: CareEntryRowVariant;
  locations: LocationForSoonestSlotForSlug[];
  maybeAddCareToTopicStack: ComponentProps<typeof CareEntryListRow>["maybeAddCareToTopicStack"];
  maybeToggleDialog: ComponentProps<typeof CareEntryListRow>["maybeToggleDialog"];
  reasons: ReasonForCareEntryIcon[];
  selectedLocation?: SelectedLocation;
  viewId?: string;
}) => {
  const CareDiscoverySearchTopicRenderer = (inputs: RenderTopicInputs) => (
    <CareEntryListRow
      cypressKey={`topic-expansion-results-${inputs.care.slug}-${inputs.result.slug}`}
      rank={inputs.index + 1}
      result={inputs.result}
      selectedLocation={selectedLocation}
      reasons={reasons}
      locations={locations}
      variant={careEntryRowVariant}
      reportClickEvent={inputs.reportClickEvent}
      maybeToggleDialog={maybeToggleDialog}
      maybeAddCareToTopicStack={maybeAddCareToTopicStack}
      viewId={viewId}
    />
  );
  return CareDiscoverySearchTopicRenderer;
};

CareDiscoverySearch.displayName = "CareDiscoverySearch";

export default CareDiscoverySearch;
